import React from 'react';

import style from './Spinner.scss';

import cn from 'classnames';

interface IProps {
  className?: string;
}

export const Spinner = (props: IProps) => <div className={cn(style.wrapper, props.className)} />;
