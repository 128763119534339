import React from 'react';

import { Layout } from '../components/common';
import { SimplePagination } from '../components/common';
import { PostGrid } from '../components/Post/Grid';
import { Spinner } from '../components/Spinner';
import CloseIcon from '../images/Close';

import style from '../styles/pages/search.scss';

import cn from 'classnames';
import { graphql, navigate } from 'gatsby';
import * as JsSearch from 'js-search';
import qs from 'qs';
import { IPostCard } from 'src/components/Post/Card';
import config from 'utils/siteConfig';

interface IProps {
  data: {
    allGhostPost: {
      edges: {
        node: {};
      }[];
    };
    allGhostPage: {
      edges: {
        node: {};
      }[];
    };
  };
  location: any;
}

interface IState {
  caretIndex: number | undefined;
  isEditing: boolean;
  isLoading: boolean;
  searchQuery: string;
  searchResults: {
    node: any;
  }[];
}

class Search extends React.Component<IProps, IState> {
  dataToSearch: any = null;
  queryInput: any;
  caret: any;

  constructor(props: IProps) {
    super(props);
    const { q = '' } = qs.parse(props.location.search.slice(1));
    this.state = {
      caretIndex: undefined,
      isEditing: false,
      isLoading: false,
      searchQuery: String(q),
      searchResults: [],
    };
    this.queryInput = React.createRef();
    this.caret = React.createRef();
  }

  componentDidMount() {
    this.setState({ isLoading: true, searchResults: [] });
    setTimeout(() => {
      this.search();
    }, 250);
  }

  componentDidUpdate(prevProps: IProps) {
    const { q: prevQuery } = qs.parse(prevProps.location.search.slice(1));
    const { q: nextQuery } = qs.parse(this.props.location.search.slice(1));

    if (prevQuery !== nextQuery) {
      this.setState({ isLoading: true, searchResults: [], searchQuery: String(nextQuery) });
      setTimeout(() => {
        this.search();
      }, 250);
    }
  }

  buildIndex() {
    const { data } = this.props;

    const pages = data.allGhostPage.edges.map(({ node }) => node);
    const posts = data.allGhostPost.edges.map(({ node }) => node);

    const dataToSearch = new JsSearch.Search('id');
    /**
     *  defines a indexing strategy for the data
     * more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex('id');
    dataToSearch.addIndex('title'); // sets the index attribute for the data
    dataToSearch.addIndex('plaintext'); // sets the index attribute for the data
    dataToSearch.addIndex('custom_excerpt'); // sets the index attribute for the data
    dataToSearch.addIndex(['primary_author', 'name']); // sets the index attribute for the data
    dataToSearch.addDocuments([...pages, ...posts]); // adds the data to be searched

    this.dataToSearch = dataToSearch;
  }

  search() {
    if (!this.dataToSearch) {
      this.buildIndex();
    }

    const { location } = this.props;

    const { q = '' } = qs.parse(location.search.slice(1));
    const searchResults = this.dataToSearch.search(q);

    this.setState({
      isLoading: false,
      searchResults: searchResults.map((p: IPostCard) => ({ node: p })),
    });
  }

  handlePageChange = (nextPageNumber: number) => {
    const { location } = this.props;
    const search = qs.parse(location.search.slice(1));

    navigate(`${location.pathname}?${qs.stringify({ ...search, p: nextPageNumber })}`);
  };

  handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    this.handleQueryUpdate();
  };

  handleDeleteClick = () => {
    this.setState({ searchQuery: '', isEditing: true });
    if (this.queryInput && this.queryInput.current) {
      this.queryInput.current.focus();
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let caretIndex = this.queryInput.current.selectionStart;

    // A workaround for a Chrome bug on Android which
    // causes selectionStart to be always 0 regardless of input change.
    // See https://stackoverflow.com/questions/44830173/unexpected-caret-reposition-in-android-chrome
    if (typeof navigator !== 'undefined' && /Android/i.test(navigator.userAgent)) {
      const { searchQuery, caretIndex: prevIndex = 0 } = this.state;
      caretIndex = prevIndex + e.target.value.length - searchQuery.length;
      this.queryInput.current.selectionStart = caretIndex;
      this.queryInput.current.selectionEnd = caretIndex;
    }

    if (window.innerWidth <= 768) {
      const offset = Math.abs(document.body.getBoundingClientRect().top);
      const { top, bottom } = this.caret.current.getBoundingClientRect();
      if (top < 80) {
        window.scrollTo({
          behavior: 'smooth',
          top: offset - 80,
        });
      } else if (bottom > window.innerHeight) {
        window.scrollTo({
          behavior: 'smooth',
          top: offset + window.innerWidth,
        });
      }
    }

    this.setState({
      caretIndex,
      searchQuery: e.target.value,
    });
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      this.queryInput.current.blur();
    }
  };

  handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
      this.setState({ caretIndex: this.queryInput.current.selectionStart });
    }
  };

  handleBlur = () => {
    this.handleQueryUpdate();
  };

  handleQueryUpdate = () => {
    const { searchQuery } = this.state;
    const { location } = this.props;
    const search = qs.parse(location.search.slice(1));
    if (searchQuery.trim()) {
      // Do not change page if search query hasn't been changed.
      if (searchQuery !== search.q) {
        navigate(`${location.pathname}?${qs.stringify({ q: searchQuery })}`);
      }
      this.setState({ isEditing: false, caretIndex: undefined });
    } else {
      const { q = '' } = search;
      this.setState({ searchQuery: String(q), isEditing: false, caretIndex: undefined });
    }
  };

  handleQueryClick = (index: number) => {
    const { searchQuery, isEditing } = this.state;
    if (!isEditing) {
      const selectionStart = Math.min(index + 1, searchQuery.length);
      this.setState({ isEditing: true, caretIndex: selectionStart });
      this.queryInput.current.focus();
      this.queryInput.current.selectionStart = selectionStart;
      this.queryInput.current.selectionEnd = selectionStart;
    }
  };

  renderResults() {
    const { location } = this.props;
    const { searchResults } = this.state;
    const { p } = qs.parse(location.search.slice(1));
    const humanPageNumber = parseInt((p as string) || '1', 10);
    const numberOfPages = Math.ceil(searchResults.length / config.postsPerPage);
    const startIndex = (humanPageNumber - 1) * config.postsPerPage;

    return searchResults.length > 0 ? (
      <div className={style.results}>
        <div className={style.resultsCount}>
          <div className="container">
            <div className="row">
              <span>{searchResults.length} results found</span>
            </div>
          </div>
        </div>
        <PostGrid posts={searchResults.slice(startIndex, startIndex + config.postsPerPage)} />
        <div className={cn('container', { [style.singlePage]: numberOfPages === 1 })}>
          <SimplePagination
            numberOfPages={numberOfPages}
            humanPageNumber={humanPageNumber}
            onPageChange={this.handlePageChange}
          />
        </div>
      </div>
    ) : (
      <div className="container">
        <div className={style.noResults}>Sorry, nothing found. Zippo…</div>
      </div>
    );
  }

  render() {
    const { isEditing, isLoading, searchQuery } = this.state;
    let { caretIndex } = this.state;
    if (caretIndex === undefined) {
      caretIndex = searchQuery.length;
    }

    return (
      <Layout headerClassName={style.header} mainClass={style.main}>
        <div className={style.hero}>
          <div className="container">
            <div className={style.subtitle}>Search</div>
            <h1>
              {searchQuery
                .slice(0, caretIndex)
                .split('')
                .map((letter, index) => (
                  <span key={index} onClick={this.handleQueryClick.bind(null, index)}>
                    {letter}
                  </span>
                ))}
              {isEditing ? '' : ' '}
              <span className={style.caretWrapper}>
                {isEditing ? (
                  <span ref={this.caret} className={style.caret}>
                    |
                  </span>
                ) : (
                  <button className={style.deleteBtn} onClick={this.handleDeleteClick}>
                    <CloseIcon width={16} height={15} />
                  </button>
                )}
                <form action="#" onSubmit={this.handleFormSubmit}>
                  <input
                    ref={this.queryInput}
                    type="search"
                    autoCapitalize="off"
                    className={style.queryInput}
                    value={searchQuery}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    onKeyUp={this.handleKeyUp}
                    onBlur={this.handleBlur}
                  />
                </form>
              </span>
              {searchQuery
                .slice(caretIndex)
                .split('')
                .map((letter, index) => (
                  <span key={index}>{letter}</span>
                ))}
            </h1>
          </div>
        </div>
        <div className={cn('container', style.searchWrapper)}>
          {isLoading ? <Spinner className={style.spinner} /> : this.renderResults()}
        </div>
      </Layout>
    );
  }
}

export default Search;

export const pageQuery = graphql`
  query {
    allGhostPage {
      edges {
        node {
          custom_excerpt
          excerpt
          feature_image
          featured
          id
          plaintext
          primary_author {
            name
            profile_image
          }
          published_at
          slug
          tags {
            slug
            name
          }
          title
        }
      }
    }
    allGhostPost {
      edges {
        node {
          custom_excerpt
          excerpt
          feature_image
          featured
          id
          plaintext
          primary_author {
            name
            profile_image
          }
          published_at
          slug
          tags {
            slug
            name
          }
          title
        }
      }
    }
  }
`;
